.user-input-section {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow-y: auto;
}

.input-box {
	margin-left: 10px;
	width: 150px;
	text-align: center;
	border: 2px solid #dddddd;
	border-radius: 5px;
	padding: 5px;
	outline: none;
}

.input-box:focus {
	border-color: #319795;      /* Change to a new color on focus (orange-red in this case) */
	box-shadow: 0 0 5px rgba(255, 69, 0, 0.5);  /* Optional: add a glow effect with the same color */
  }

.highlight {
	background-color: green !important;
	color: white !important;
	border: 2px solid black;
}

.choice-buttons button {
	background-color: #ccc;
	/* Nondescript background color */
	border: none;
	padding: 10px 20px;
	margin: 5px;
	font-size: 16px;
	cursor: pointer;
	border-radius: 5px;
	transition: all 0.3s ease;
}

.choice-buttons button:hover {
	background-color: green;
	color: white;
}

.chat-tag-instructions {
	background-color: teal;
	color: white;
	padding: 10px;
	border-radius: 30px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}


.chat-tag-instructions:hover,
.chat-tag-instructions button:hover {
	background-color: #004d40;
}

.chat-tag-instructions i {
	font-size: 1.5rem;
	margin-right: 8px;
}

.instructions-section {
	position: sticky;
	text-align: left;
	padding: 1rem;
	border-top: 2px solid rgba(0, 0,0, 0.2);
	background-color: #fef3b2;
	font-weight: bold; 
	font-size: 18px;
	border-bottom: 2px solid rgba(0, 0,0, 0.2);
}