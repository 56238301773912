.chat-tag {
  position: absolute;
  top: 10%;
  right: 0;
  transform: translateY(-50%);
  background-color: teal;
  color: white;
  padding: 10px 15px;
  border-radius: 30px 0 0 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.chat-tag:hover,
.chat-tag button:hover {
  background-color: #004d40;
  /* Darker teal on hover */
}

.chat-tag i {
  font-size: 1.5rem;
  margin-right: 8px;
}