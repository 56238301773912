.teacher-chat {
    flex: 1;
    overflow-y: auto; /* Allow vertical scrolling for large chat content */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    word-wrap: break-word; /* Ensure long words break instead of overflow */
    height: 50vh; /* Full viewport height or adjust as necessary */
    width: 100%;
  }
  
  .chat-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    height: 100%;
  }
  
  .chat-history {
    flex: 1;
    margin-left: 10px;
    margin-top: 8px;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc; /* Optional: To separate visually from history */
  }
  
  .chat-input input {
    flex: 1; /* Takes up all available space */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .chat-input button {
    margin-left: 10px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .chat-input button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  