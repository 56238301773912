.intro-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.intro-image {
    width: 400px;
    object-fit: contain;
    max-width: 100%;
    height: auto;
    margin-right: 20px;
}

.intro-text {
    flex: 1;
    text-align: left;
    font-size: large;
}

.intro-text h3 {
    font-size: 3em;
}
