.guide {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.guide ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 500px;
}

.guide, li {
  display: 'flex';
  justify-content: center;
}

.guide button {
  padding: 5px;
  width: 90%;
  text-align: left;
}