.App {
  display: flex;
  flex-direction: row;
  text-align: center;
  height: 100vh;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h2 {
  font-size: xx-large;
}

.Panel2 {
  display:flex;
  flex-direction: column;
  flex-grow: 1;
   width: 100%;
  /* width:fit-content */
}

.Panel2 tbody tr:nth-child(odd) {
  background-color: #cbeefe; /* Light grey background */
}

.Panel2 tbody tr:nth-child(even) {
  background-color: #ffffff; /* White background */
}

.section {
  flex: 3;
  overflow-y: auto; /* Only the middle section is scrollable */
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.right-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Space between chat and user input */
  background-color: #f1f1f1; /* Background color for visual purposes */
  overflow-y: hidden;
  overflow-x: hidden;
  width: 30%; /* Full width of the right column */
  height: 100%;
  box-sizing: border-box;
}

.separator {
  width: 100%;
  background-color: red;
  border: 1px solid black;
}

button {
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}

.multiple-choice-question {
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  background-color: #eee;
  text-align: left;
  padding: 10px;
}

.multiple-choice-question-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}